document.addEventListener('turbolinks:load', function() {
  var photosDiv = document.querySelector("#photos");
  if (!photosDiv) { return; };

  function bindUploadButton() {
    var uploadButton = document.querySelector(".upload");
    uploadButton.addEventListener('click', function(e) {
      e.preventDefault();
      hideFullScreen();
      var modal = document.querySelector("#upload.modal");
      modal.classList.add("active");
      showShroud();
    });
  };
  bindUploadButton();

  function ajax(url, callback){
    var xmlhttp;
    // compatible with IE7+, Firefox, Chrome, Opera, Safari
    xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function(){
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200){
        callback(xmlhttp.responseText);
      }
    }
    xmlhttp.open("GET", url, true);
    xmlhttp.send();
  }

  function htmlForPhoto(photoObject) {
    var html = "<img data-full='" +
      photoObject.full_url +
    "' src='" + photoObject.thumbnail_url + "'>";
    return html;
  }

  function fetch() {
    document.querySelector(".loading").style.display = "block";
    var seenIds = document.querySelector("#photos").getAttribute("data-seen-ids");
    var parent = document.querySelector(".m-p-g__thumbs");
    parent.innerHTML = ""
    ajax("/photos.json?seen_ids=" + seenIds + "&count=20", function(response) {
      photos = JSON.parse(response);
      photos.forEach(function(photo, i) {
        var div = document.createElement("div");
        div.classList.add("polaroid");
        div.innerHTML = htmlForPhoto(photo);
        parent.appendChild(div);
        if (seenIds != "") {
          seenIds += ","
        }
        seenIds += photo.id;
      });
      document.querySelector("#photos").setAttribute("data-seen-ids", seenIds);
      document.querySelector(".loading").style.display = "none";
      shuffle();
      bindPhotos();
    });
  }

  function bindFetchButton() {
    var fetchButton = document.querySelector(".fetch");
    fetchButton.addEventListener('click', function(e) {
      e.preventDefault();
      return fetch();
    });
  }
  bindFetchButton();

  function sendToCorner(elem) {
    var width = windowWidth();
    var height = windowHeight();
    var offset = 100;

    var corner = Math.random()
    if (corner < 0.25) {
      elem.style.top = "-" + offset + "px";
      elem.style.left = "-" + offset + "px";
    } else if (corner < 0.5) {
      elem.style.top = "-" + offset + "px";
      elem.style.left = width + offset + "px";
    } else if (corner < 0.75) {
      elem.style.top = height + offset + "px";
      elem.style.left = width + offset + "px";
    } else {
      elem.style.top = height + offset + "px";
      elem.style.left = "-" + offset + "px";
    }
  }

  function windowWidth() {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  }

  function windowHeight() {
    return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  }

  function windowAspectRatio() {
    return windowHeight()/windowWidth();
  }

  function windowIsPortrait() {
    return windowAspectRatio() >= 1;
  }

  function shuffle() {
    var photos = document.querySelectorAll(".polaroid");
    if (photos.length === 0) { return; };

    var width = windowWidth();
    var height = windowHeight();
    var perPhoto = width*height / photos.length;
    var photoSide = Math.sqrt(perPhoto) * 0.75;
    var photosPerRow = Math.floor(width / photoSide);
    var row = 0;

    photos.forEach(function (elem, i) {
      var rotation = Math.floor(Math.random() * 40) - 20;
      elem.style.transform = "rotate(" + rotation + "deg)";
      sendToCorner(elem);
      elem.style.transition = "all 0.35s ease-in-out";
      elem.style.zIndex = Math.floor(Math.random() * photos.length);
      elem.querySelector("img").style.maxWidth = windowWidth() > 768 ? "400px" : "200px";
      elem.style.height = "auto";
      elem.style.width = "auto";
      var top = row*photoSide*1.5;
      var left = (i % photosPerRow)*photoSide;
      setTimeout(function() {
        rotation = Math.floor(Math.random() * 40) - 20;
        elem.style.transform = "rotate(" + rotation + "deg)";
        elem.style.top = top + "px";
        elem.style.left = left + "px";
        elem.style.transition = "all 0.35s ease-in-out";
        document.querySelector(".loading").style.display = "none";
      }, 350);
      setTimeout(function() {
        elem.style.transition = "none";
      }, 750);
      if ((i + 1) % photosPerRow === 0 && i > 0) { row += 1; }
    });
    hideFullScreen();
    hideShroud();
    hideModal();
  };
  shuffle();

  function aspectRatio(imgElem) {
    return imgElem.height/imgElem.width;
  }

  function isPortrait(imgElem) {
    return aspectRatio(imgElem) >= 1;
  }

  function showShroud() {
    var shroud = document.querySelector("#shroud");
    shroud.style.opacity = "0.9";
    shroud.style.pointerEvents = "all";
  }

  function hideShroud() {
    var shroud = document.querySelector("#shroud");
    shroud.style.opacity = "0";
    shroud.style.pointerEvents = "none";
  }

  function bringToFront(elem) {
    showShroud();

    var fullScreenElem = document.querySelector("#full-screen");
    var fullScreenImg = fullScreenElem.querySelector("img");

    imgElem = elem.querySelector('img');
    fullScreenImg.src = imgElem.src;
    fullScreenImg.src = imgElem.dataset.full;

    fullScreenElem.style.opacity = 1;
    fullScreenElem.style.pointerEvents = "all";
    if (isPortrait(imgElem) && !windowIsPortrait()) {
      fullScreenElem.style.width = windowHeight()/aspectRatio(imgElem) + "px";
      fullScreenElem.style.height = "100%";
      fullScreenImg.style.width = "100%";
      fullScreenImg.style.maxWidth = "none";
      fullScreenElem.style.top = 0;
      fullScreenElem.style.left = "50%";
      fullScreenElem.style.transform = "translateX(-50%)";
    } else {
      fullScreenElem.style.width = "100%";
      fullScreenElem.style.height = windowWidth()*aspectRatio(imgElem) + "px";
      fullScreenImg.style.width = "100%";
      fullScreenImg.style.maxWidth = "none";
      fullScreenElem.style.left = 0;
      fullScreenElem.style.top = "50%";
      fullScreenElem.style.transform = "translateY(-50%)";
    }
  }

  function bindPhotos() {
    var photos = document.querySelectorAll(".polaroid");
    photos.forEach(function (elem, i) {
      elem.addEventListener('click', function(e) {
        e.preventDefault();
        bringToFront(elem);
      });
    });
  }
  bindPhotos();

  function bindShuffleButton() {
    var shuffleButton = document.querySelector(".shuffle");
    shuffleButton.addEventListener('click', function(e) {
      e.preventDefault();
      shuffle();
    });
  }
  bindShuffleButton();

  function bindCloseModalButton() {
    var closeButton = document.querySelector("#upload .btn-clear");
    closeButton.addEventListener('click', function(e) {
      e.preventDefault();
      hideShroud();
      hideModal();
    });
  }
  bindCloseModalButton();

  function hideModal() {
    var modal = document.querySelector("#upload.modal");
    modal.classList.remove("active");
  }

  function bindShroud() {
    var shroud = document.querySelector("#shroud");
    shroud.addEventListener('click', function() {
      hideShroud();
      hideModal();
      hideFullScreen();
    })
  }
  bindShroud();

  function hideFullScreen() {
    var fullScreenElem = document.querySelector("#full-screen");
    fullScreenElem.style.opacity = "0";
    fullScreenElem.style.pointerEvents = "none";
  }

  function bindFullScreen() {
    var fullScreenElem = document.querySelector("#full-screen");
    fullScreenElem.addEventListener('click', function(e) {
      hideShroud();
      hideFullScreen();
    });
  }
  bindFullScreen();
});
